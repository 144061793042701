import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageContextProps, PageInfoProps, PublicationProps } from '../types/pages'
import { SectionBase } from '../types/sections'
// Components
import { Layout, Seo } from '../components/commons'
import Section from '../components/sections'
import { sectionComparisonFragment } from '../components/sections/Comparison/Comparison.fragment'
import { sectionProductValues } from '../components/sections/ProductValues/ProductValues.fragment'

interface LandingQuery {
  datoCmsLanding: {
    pageInfo: PageInfoProps
    meta: PublicationProps
    sections: SectionBase[]
  }
}

const LandingPage: React.FC<PageProps<LandingQuery, PageContextProps>> = ({ data, pageContext }) => {
  const {
    datoCmsLanding: { pageInfo, meta, sections },
  } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={pageInfo.hidePrefooter}
    >
      <Seo
        slug={pageInfo.slug}
        canonicalUrl={pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={meta}
        content={pageInfo.seo}
      />
      {sections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
    </Layout>
  )
}

export default LandingPage

export const query = graphql`
  query LandingQuery($id: String!, $locale: String!) {
    datoCmsLanding(locale: $locale, id: { eq: $id }) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      sections {
        ...sectionHeroLightFragment
        ...sectionHeroFragment
        ...sectionLogoListFragment
        ...sectionFeatureSimpleFragment
        ...sectionMarqueeFragment
        ...sectionFAQFragment
        ...sectionTestimonialFragment
        ...sectionDestinationListFragment
        ...sectionKPIFragment
        ...sectionTwoImagesFragment
        ...sectionTeamMembersFragment
        ...sectionBenefitsFragment
        ...sectionImagesMarqueeFragment
        ...sectionAboutHeroFragment
        ...sectionPushFragment
        ...sectionFeaturedArticlesFragment
        ...sectionFeaturedIntegrationsFragment
        ...sectionFeaturedTutorialsFragment
        ...sectionFeaturedUseCasesFragment
        ...sectionContentFragment
        ...sectionDownloadFormFragment
        ...sectionFeaturedCustomerStoriesFragment
        ...sectionFeaturedExternalResourcesFragment
        ...sectionPartnersFragment
        ...sectionTestimonialSlideshowFragment
        ...sectionComparisonFragment
        ...sectionProductValues
      }
    }
  }
`
